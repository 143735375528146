import { createUseStyles } from 'react-jss';

export default createUseStyles(({ spacing, text }) => ({
  container: {
    position: 'relative',
  },

  pageTitle: {
    ...text.dropDown,
    fontWeight: 500,
    margin: 0,
    marginBottom: spacing * 3,
  },

  mobileTitle: {
    ...text.main,
    fontFamily: 'Work Sans',
    fontWeight: 600,
    marginTop: 0,
    marginBottom: spacing * 3,
    '@media (min-width: 800px)': {
      display: 'none',
    },
  },

  desktopTitle: {
    ...text.main,
    fontFamily: 'Work Sans',
    fontWeight: 600,
    marginTop: 0,
    marginBottom: spacing * 3,
    '@media (max-width: 800px)': {
      display: 'none',
    },
  },

  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(50vw - 100px)',
    paddingRight: 10,
  },

  imageContainer: {
    position: 'relative',
    marginLeft: '4vw',
    width: '124px',
    height: '155px',
    background: '#fff',
  },

  textContainer: {
    width: 'calc(100% - 124px - 4vw)',
  },

  animate: {
    width: '100%',
    position: 'absolute',
    paddingBottom: 20,
  },

  '@media (min-width: 1100px) and (max-width: 1600px)': {
    container: {
      width: spacing * 60,
    },
  },

  '@media (max-width: 800px)': {
    contentContainer: {
      position: 'relative',
      top: 0,
      left: 0,
      flexDirection: 'column-reverse',
      paddingRight: 0,
    },

    imageContainer: {
      marginLeft: 0,
      marginBottom: spacing * 4.6,
    },

    textContainer: {
      width: 'calc(100vw - 72px)',
    },
  },
}));

export const animationProps = {
  initial: { opacity: 0, x: '95vw', width: 0, overflow: 'hidden' },
  animate: { opacity: 1, x: 0, width: '100%', overflow: 'visible' },
  exit: { opacity: 0, x: '-95vw', width: 0, overflow: 'hidden' },
  transition: {
    x: { type: 'spring', stiffness: 300, damping: 30 },
    opacity: { duration: 0.1 },
    default: { delay: 0.3, duration: 0 },
  },
};
